/*Preloader*/
$('document').ready(function () {
    if (getCookie('preloader') != 'off') {
        $('.preloader').show();
        setTimeout(function () {
            $('.preloader__desc').show();
            setCookie('preloader', 'off', 7);
            $('.preloader').addClass('animated');
            setTimeout(function () {
                $('.preloader').fadeOut();
            }, 2000);
        }, 500);
    }
});
/**/

chosenInit();

/*Header dropdown*/
$('.header-dropdown__title').click(function () {
    let dropdownWrapper = $(this).closest('.header-dropdown');
    if (!dropdownWrapper.hasClass('opened')) {
        $('.header-dropdown.opened').removeClass('opened');
        dropdownWrapper.addClass('opened');
    } else {
        dropdownWrapper.removeClass('opened');
    }
});
$(document).click(function (event) {
    //Close profile context menu if anything except itself or profile link is clicked
    if (!$(event.target).closest('.header-dropdown').length) {
        if ($('.header-dropdown').hasClass('opened')) {
            $('.header-dropdown').removeClass('opened');
        }
    }
});
/**/

/*Spoilers*/
$('.spoiler__title').click(function () {
    //independent spoilers
    if ($(this).closest('.spoilers').hasClass('spoilers--independent')) {
        $(this).siblings('.spoiler__desc').slideToggle(300).closest('.spoiler').toggleClass('active');
    }
    //dependent spoilers
    else {
        if ($(this).siblings('.spoiler__desc').is(':visible')) {
            $(this).siblings('.spoiler__desc').slideUp(300).closest('.spoiler').removeClass('active');
        } else {
            $(this).closest('.spoilers').find('.spoiler__desc:visible').slideUp(300).closest('.spoiler').removeClass('active');
            $(this).siblings('.spoiler__desc').slideDown(300).closest('.spoiler').addClass('active');
        }
    }
});
/**/

/*Team spoiler*/
$('.team__btn').click(function () {
    $(this).closest('.team').find('.team__desc').slideToggle(400).closest('.team').toggleClass('minimized');
});

/*Catalog tabs*/
$('.catalog-link').click(function () {
    $(this).addClass('active').siblings('.active').removeClass('active');
    $(this).closest('.catalog').find('.catalog-tab').eq($(this).index()).addClass('active').siblings('.active').removeClass('active');
    $('.catalog-tab.active .simplebar-content-wrapper').scrollTop(0);
});

/*Disable touch on fancybox modal*/
$(function () {
    $('[data-fancybox]').fancybox({
        touch: false
    });
});
/**/

/*Left nav spoiler*/
$('.left-nav__title').click(function () {
    $(this).siblings('.left-nav__list').slideToggle(300).closest('.left-nav').toggleClass('collapsed');
});
/**/

/*Wrap frame, object, embed tags*/
$('iframe, object, embed', '.plain-text').each(function () {
   $(this).wrap('<div class="videoWrapper"></div>')
});
/**/

/*Cookie operations*/
function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

//Chosen-select init
function chosenInit() {
    if ($('.chosen').length) {
        var lang = document.getElementById('lang').value,
            resultMessage;
        if (lang == 'en') {
            resultMessage = 'No results found for'
        }
        else if (lang == 'kk') {
            resultMessage = 'Нәтижелер жоқ'
        }
        else {
            resultMessage = 'Ничего не найдено по запросу';
        }

        $(".chosen").chosen({
            search_contains: true,
            no_results_text: resultMessage
        });
    }
}

//Change name if the file was chosen
$(document).ready(function(){
    $('.file-input').each(function () {
        var fileName = $(this).find('.file-name'),
            title = fileName.html();
        $('input', $(this)).bind('change', function() {
            var files = $(this).prop("files");
            var names = $.map(files, function(val) { return val.name; }).join( ", " );
            if (files.length <= 0) {
                fileName.html(title);
            }
            else {
                fileName.html(names);
            }
        })
    })
});

/*Menu-btn*/
$('.menu-btn').click(function () {
    $(this).toggleClass('opened');
    $('.header-inner').toggleClass('opened');
    if ($(this).hasClass('opened')) {
        bodyUnscrollable();
    } else {
        bodyScrollable();
    }
});
/**/

/*Authorization is shown in modal on mobile devices*/
if ($(window).width() < 1025) {
    $('#auth-btn').click(function (e) {
        e.preventDefault();
        $.fancybox.open({
            src: "#auth-modal",
            touch: false
        })
    });
}
/**/

$('#printBtn').click(function () {
    PrintElem('.main-wrapper');
});

//Get scrollbar width
function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

//Make body unscrollable
function bodyUnscrollable() {
    if (!$('body').hasClass('no-scroll')) {
        var scrollTop = $(window).scrollTop();
        //Detect all fixed elements on the page
        var find = $('*').filter(function () {
            return $(this).css('position') == 'fixed';
        });
        $('.main-wrapper').css('margin-top', -scrollTop);
        $('body').addClass('no-scroll').css('margin-right', getScrollbarWidth());
        find.css('margin-right', getScrollbarWidth());
    }
}

//Make body scrollable
function bodyScrollable() {
    if ($('body').hasClass('no-scroll')) {
        var scrollTop = $('.main-wrapper').css('margin-top').slice(0, -2);
        //Detect all fixed elements on the page
        var find = $('*').filter(function () {
            return $(this).css('position') == 'fixed';
        });
        $('body').removeClass('no-scroll').css('margin-right', 0);
        find.css('margin-right', 0);
        $('.main-wrapper').css('margin-top', 0);
        $(window).scrollTop(-scrollTop);
    }
}

function PrintElem(elem) {
    Popup($(elem).html());
}

function Popup(data) {
    var mywindow = window.open('', 'new div', 'height=768,width=1366');
    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('<link rel="stylesheet" href="/assets/css/style.css" type="text/css" media="all"/>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<style>.header-dropdown.language, .header-top .search-btn, .buttons, .header-middle .socials, .header-bottom, .mobile-header__right, .mobile-header__left, .footer{display: none;}</style>')
    mywindow.document.write(data);
    mywindow.document.write('</body></html>');

    mywindow.focus();
    setTimeout(function(){
        mywindow.stop();
        mywindow.print();
        mywindow.close();
    },2000);

    return true;
}